import React from 'react';
import { useAuth } from 'modules/auth/hooks';
import { ModalMarker, AlertMarker } from 'modules/common/modal';
import ToastMarker from 'modules/common/toast';
import ErrorBoundary from 'modules/common/components/ErrorBoundary';
import ScreenLoader from 'modules/common/components/ScreenLoader';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

const Public = React.lazy(() => import('./Public'));
const Private = React.lazy(() => import('./Private'));

function App() {
  const [isAppReady, isAuthenticated] = useAuth();

  if (!isAppReady) return null;
  return (
    <ErrorBoundary>
      <ToastMarker />
      <AlertMarker />
      <ModalMarker />
      <React.Suspense fallback={<ScreenLoader />}>
        {isAuthenticated ? <Private /> : <Public />}
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default App;
