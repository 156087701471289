import React from 'react';
import PropTypes from 'prop-types';
import cityHall from '../../../assets/images/illustrations/city-hall.svg';

function ScreenLoader({ label }) {
  return (
    <div className="w-full h-full bg-white bg-opacity-100 absolute inset-0">
      <div className="flex justify-center items-center h-full bg-opacity-50">
        <div className="text-lg text-gray-500 font-semibold">{label}</div>
      </div>
    </div>
  );
}

ScreenLoader.defaultProps = {
  label: (
    <div className="flex relative items-center">
      <img src={cityHall} alt="" className="mr-3 loading-img" />
      <div className="loading-items font-bold text-primary-500 pt-7">
        <h1>GO! MANILA</h1>
      </div>
    </div>
  ),
};

ScreenLoader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default ScreenLoader;
